<template>
  <div class="content">
    <!-- <input type="radio" :value="0" v-model="maptype" @change="initMap()" />世界
    <input type="radio" :value="1" v-model="maptype" @change="initMap()" />中国 -->
    <div class="content-title">
        <div class="content-title-time"><span>{{currentDate}}</span> <span>{{nowTime}}</span></div>
    </div>
    <div class="toparea">
        <div class="mapitem" v-for="(map, index) in mapArea" :key="map.name">
          <span @click="tabMap(map, index)">{{ map.name }}</span>
          <i v-if="showIcons(index)">></i>
        </div>
    </div>
    <div class="content-cont">
      <div class="cont-left">
          <div class="cont-left-top">
            <div class="cont-title-tier">
              <div class="cont-title">
                  总代理数据
              </div>
            </div>
            <div class="agency">
              <div class="agency-list">
                <div class="agency-item">
                  <dv-digital-flop :config="agencyConfig" style="width:165px;height:47px;" />
                  <div class="agency-item-text">全国代理数量</div>
                </div>
                <div class="agency-item">
                  <div class="agency-item">
                  <dv-digital-flop :config="provinceConfig" style="width:165px;height:47px;" />
                  <div class="agency-item-text">省级代理数量</div>
                </div>
                </div>
              </div>
              <div class="agency-list">
                <div class="agency-item">
                  <div class="agency-item">
                  <dv-digital-flop :config="townConfig" style="width:165px;height:47px;" />
                  <div class="agency-item-text">市级代理数量</div>
                </div>
                </div>
                <div class="agency-item">
                  <div class="agency-item">
                  <dv-digital-flop :config="areaConfig" style="width:165px;height:47px;" />
                  <div class="agency-item-text">区级代理数量</div>
                </div>
                </div>
              </div>
            </div>
            <div class="agency-title">
                <img src="../assets/map/agencyIcon.png" alt="">
                <div>区域代理数量排行</div>
            </div>
            <div class="agency-columnar" ref="columnar">
              <columnar :columnarHeight="columnarHeight" :dataList="dataList"></columnar>
            </div>
          </div>
          <div class="cont-left-bom">
            <div class="cont-title-tier">
              <div class="cont-title">
                  全国代理趋势
              </div>
            </div>
            <div class="agency-tendency" ref="tendency">
              <tendency :tendencyHeight="tendencyHeight" :tendencyList="tendencyList"></tendency>
            </div>
          </div>
      </div>
      <div class="box" ref="box1">
      
      </div>
      <div class="cont-right">
        <div class="cont-left-top">
          <div class="cont-title-tier">
              <div class="cont-title">
                当前代理地区(全国)
              </div>
          </div>
          <div class="tier-list" ref="board">
            <dv-scroll-board :config="boardConfig" :style="`width:100%;min-height:${boardHeight}px`" />
          </div>
        </div>
        <div class="cont-left-bom">
          <div class="cont-title-tier">
              <div class="cont-title">
                省市区代理占比
              </div>
            </div>
            <div class="agency-proportion" ref="proportion">
              <proportion :proportionHeight="proportionHeight" :proportionData="infoData"></proportion>
            </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from "axios"
import * as echarts from 'echarts';
import 'echarts-gl'
import china from "../assets/json/100000.json"
import columnar from "./columnar.vue";
import tendency from "./tendency.vue";
import proportion from "./proportion.vue";
export default {
  name: "Map",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    columnar,
    tendency,
    proportion
  },
  data() {
    return {
      // 0-世界、1-国家、2-省份、3-市区、4-区
      maptype: 1,
      chart: undefined,
      mapArea: [],
      defaultData: [],
      waperStyle: {},
      kehudata: [],
      urldataArray: {
        "测试1": "http://www.baidu.com",
        "客户E": "https://www.yuque.com/",
      },
      currentDate:'',
      nowTime:'',
      agencyConfig:{
        number: [0],
        content: '{nt}',
        style: {
          fontSize: 28,
          fill: '#00F6FF'
        }
      },
      provinceConfig:{
        number: [0],
        content: '{nt}',
        style: {
          fontSize: 28,
          fill: '#00F6FF'
        }
      },
      townConfig:{
        number: [0],
        content: '{nt}',
        style: {
          fontSize: 28,
          fill: '#00F6FF'
        }
      },
      areaConfig:{
        number: [0],
        content: '{nt}',
        style: {
          fontSize: 28,
          fill: '#00F6FF'
        }
      },
      columnarHeight:0,
      tendencyHeight:0,
      boardHeight:0,
      proportionHeight:0,
      boardConfig:{
        header: ['序号', '代理地区', '代理地区数量'],
        headerBGC:'RGBA(0, 57, 155, 0.8)',
        evenRowBGC:'RGBA(4, 0, 100, 0.3)',
        oddRowBGC:'RGBA(3, 20, 119, 0.9)',
        align:['center','center','center'],
        data: []
      },
      mapInfoArray:{},
      infoData:{},
      dataList:[],
      tendencyList:[],
      jsonUrl:'',
      cityName:'',
    }
  },
  computed: {
    // 绑定的跳转url赋值
    filterKehu() {
      var data = [];
      this.kehudata.forEach(e => {
        var url = this.urldataArray[e];
        data.push({ name: e, url: url })
      })

      return data;
    }
  },
  created(){
    this.getCurrentTime()
    this.getScreen()
    this.getNowAgent(100000)
  },
  mounted() {
    this.columnarHeight = this.$refs.columnar.offsetHeight
    this.tendencyHeight = this.$refs.tendency.offsetHeight
    this.boardHeight = this.$refs.board.offsetHeight
    this.proportionHeight = this.$refs.proportion.offsetHeight
    var constat = this.$refs.box1;
    this.chart = echarts.init(constat);

    this.chart.on('mousemove', function (params) {
      self.waperStyle = {
        display: 'none',
      };
    });
    var self = this;
      this.chart.on('click', function (params) {
      // console.log(params,'params')
      let adcode = params?.data?.code
      if(!adcode){
        // console.log(self.mapInfoArray,'self.mapInfoArray')
         for (let i in self.mapInfoArray) {
            if(params.name== i){
              console.log(self.mapInfoArray[i][0])
              adcode = self.mapInfoArray[i][0]
            }
         }
        //  console.log(adcode,'adcode')
      }
      if (params.componentSubType == "effectScatter") {
        // 赋值客户信息
        self.kehudata = params.data.data;

        self.waperStyle = {
          display: 'block',
          top: params.event.offsetY + "px",
          left: params.event.offsetX + "px"
        };
      } else {
        self.waperStyle = {
          display: 'none',
        };

        self.cityName = params.name;
        // 层级叠加
        self.maptype++;
        let jsonUrl = ''
        // TODO:直辖市特殊处理
        if (self.maptype == 3 && (adcode.toString().indexOf("310") == 0)) {
          self.jsonUrl = `https://geo.datav.aliyun.com/areas_v3/bound/${adcode}.json`;
        } else if (self.maptype == 4) {
          self.jsonUrl = `https://geo.datav.aliyun.com/areas_v3/bound/${adcode}.json`;
        } else {
          self.jsonUrl = `https://geo.datav.aliyun.com/areas_v3/bound/${adcode}_full.json`;
        }
        self.getNowAgent(adcode)
        
      }
    });
  },
  methods: {
    getScreen(){
      axios.get('https://agent-api.66lego.cn/system/agent/agentDataBigScreen').then(res=>{
        this.infoData = res.data.data
        // console.log(this.infoData,'接口数据')
        this.dataList = this.infoData.areaAgentTotalRankList
        this.tendencyList = this.infoData.nationalAgentTrendList
        this.agentChange()
      })
    },
    agentChange(){
        this.agencyConfig.number= [this.infoData.nationalAgentTotal]
        this.agencyConfig = {...this.agencyConfig}
        this.provinceConfig.number= [this.infoData.provinceAgentTotal]
        this.provinceConfig = {...this.provinceConfig}
        this.townConfig.number= [this.infoData.cityAgentTotal]
        this.townConfig = {...this.townConfig}
        this.areaConfig.number= [this.infoData.districtAgentTotal]
        this.areaConfig = {...this.areaConfig}
    },
    getNowAgent(id){
      axios.get('https://agent-api.66lego.cn/system/agent/nowAgentArea?id='+id).then(res=>{
          // console.log(res.data)
          this.defaultData = res.data.data
          this.boardConfig.data = this.defaultData.map((item,index)=>{
            return [index+1,item.agentAreaName,item.agentAreaTotal]
          })
          this.boardConfig = {...this.boardConfig}
          if(id==100000){
            this.initMap();
          }else{
            if(this.jsonUrl){
              this.chartClick(id)
            }
          }
      })
    },
    chartClick(id){
      let adcode = id
      axios.get(this.jsonUrl).then(res => {
          // console.log(res,'res')
          var geodata = null;
          geodata = res.data;
          // 通过对应json文件获取 子对象坐标和编码
          // 到区一级就没有向下坐标了
          this.mapInfoArray = {};
          for (var i = 0; i < geodata.features.length; i++) {
            this.mapInfoArray[geodata.features[i].properties.name] = [geodata.features[i].properties.adcode, geodata.features[i].properties.center];
          }
          // console.log(self.mapInfoArray,'self.mapInfoArray');
          // 获取展示的数据信息
          if (this.maptype == 4) {
            // 县区也仅显示到县区信息
            var data = this.getLayerData(this.maptype - 1, this.cityName, this.mapInfoArray);
          } else {
            if (this.maptype == 3 && (adcode.toString().indexOf('310') == 0)) {
              var data = this.getLayerData(this.maptype - 1, this.cityName, this.mapInfoArray);
            } else {
              var data = this.getLayerData(this.maptype, this.cityName, this.mapInfoArray);
            }
          }

          // 渲染地图
          this.loadMap(geodata, this.cityName, data, this.mapInfoArray);

          // 创建顶部路径
          this.mapArea.push({ name: this.cityName, codeData: geodata, data: data, geoCoordMap: this.mapInfoArray, index: this.maptype,adcode:adcode });
        })
    },
    getCurrentTime() {
      setInterval(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        this.currentDate = `${year}-${month}-${day}`;
        this.nowTime = date.toLocaleTimeString();
      }, 1000);
    },
    initMap() {
      this.chart.showLoading();
      this.mapArea = [];

      // if (this.maptype == 0) {
      //   echarts.registerMap('world', world);

      //   // 世界地图相关暂时只用中国
      //   var geoCoordMap = { '中国': [100000, [109.44362810937501, 32.20520670312501]] };
      //   var dataArray = this.getLayerData(0, '', geoCoordMap);

      //   this.mapArea.push({ name: "世界", codeData: world, data: dataArray, geoCoordMap, index: 0 });
      //   this.loadMap(world, 'world', dataArray, geoCoordMap);

      // } else if (this.maptype == 1) {}
        echarts.registerMap('china', china);

        // 获取地图上所有的中心坐标和code
        this.mapInfoArray = {};
        for (var i = 0; i < china.features.length; i++) {
          this.mapInfoArray[china.features[i].properties.name] = [china.features[i].properties.adcode, china.features[i].properties.center];
        }

        var dataArray = this.getLayerData(1, '中国', this.mapInfoArray);
        this.mapArea.push({ name: "中国", codeData: china, data: dataArray, geoCoordMap: this.mapInfoArray, index: 1,adcode:100000 });
        this.loadMap(china, 'china', dataArray, this.mapInfoArray);
      

        this.chart.hideLoading();
    },
    // 根据名称筛选出下级地区列表和客户信息
    getLayerData(layer, filterName, mapData) {
      if (layer == 0) {
        var qyLength = 0;
        var qyNames = [];
        // 找出中国的数据
        this.defaultData.forEach(e => {
          if (e.address.indexOf("中国") > -1) {
            qyLength++;
            qyNames.push(e.agentAreaName)
          }
        })
        return [{ name: "中国", value: qyLength, qiyeData: qyNames, code: mapData["中国"][0] }]
      }

      if (layer > 0) {
        var dataArray = [];
        var areaArray = [];
        this.defaultData.forEach(e => {
          dataArray.push({ name: e.agentAreaName, value: 1, qiyeData: e.agentAreaTotal, code: e.id });
        })
        return dataArray;
      }
    },
    showIcons(index) {
      if (index == this.mapArea.length - 1) {
        return false;
      }
      return true;
    },
    // 顶部层级切换
    tabMap(map, index) {
      // console.log(map,'map')
      this.jsonUrl = ''
      this.loadMap(map.codeData, map.name, map.data, map.geoCoordMap);
      this.maptype = map.index;
      this.mapInfoArray = map.geoCoordMap
      this.mapArea.splice(index + 1);
      this.getNowAgent(map.adcode)
    },
    // jsonCode-地图json
    // name-显示的区域名称
    // data-包含的区域名称和值
    // geoCoordMap-企业位置散点图
    loadMap(jsonCode, name, data, geoCoordMap) {
      // 根据名称找出省份或城市对应坐标(散点图用)
      // console.log(jsonCode)
      // console.log(name)
      // console.log(data)
      // console.log(geoCoordMap,'geoCoordMap')
      let dataList = []
      data.map(item=>{
        if(item.qiyeData>0){
          dataList.push(item)
        }
      })
      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];

          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord[1].concat(data[i].value),
              data: data[i].qiyeData,
              code: geoCoord[0]
            });
          }
        }
        // console.log(res)
        return res;
      };

      this.chart.clear();
      // 英文格式化中文
      var nameMap = { China: "中国" }
      echarts.registerMap(name, jsonCode);
      var option = {
        nameMap: nameMap,
        visualMap: {
          min: 0,
          max: 10,
          show: false,
          calculable: true,
          seriesIndex: [1],
          inRange: {
            color: ['#3aa3e5', '#0e5db5']
          },
        },
        geo: {
          map: name,
          zoom: 1.2,
          roam: true,
          label:{
            show: true,
            textStyle: {
                color: '#fff'
            },
            emphasis: {
                  show: true,
                  textStyle: {
                      color: '#fff'
                  }
              }
            },
          itemStyle: {
            normal: {
              label: {show: true},
              color: '#F4F4F4',
              borderWidth: 1, //区域边框宽度
              borderColor: 'rgba(147, 235, 248, 1)', //区域边框颜色
              areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [{
                      offset: 0,
                      color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                  }, {
                      offset: 1,
                      color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
              },
            },
            emphasis: {
              label: {
                show: true,
                color: "#ffffff"
              },
              areaColor: '#389BB7',
            },
          }
        },
        series: [
          {//散点图
            name: 'eff',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            symbolSize: 0,
            data: convertData(dataList),
            label: {
              show: false,
              offset: [0, 0], //偏移设置
              fontSize: 14,
              formatter(value) {
                console.log(value,'123');
                return value.data.value[2];
              },
              color: '#666666',
            },
            itemStyle: {
              shadowBlur: 10,
              normal: {
                color: '#36e3ff', //标志颜色
              },
            },
          },
          {
            name: 'MAP',
            type: 'map',
            geoIndex: 0,
            mapType: name,
            zoom: 1,
            data:dataList,
          },
        ]
      };
      this.chart.setOption(option);
      window.onresize = () => {
      this.chart.resize();
    };
    },
    // 跳转到url
    gotBoard(item) {
      if (!this.isEdit && item.url) {
        window.open(item.url)
      }
    }
  },
}
</script>

<style>
.content{
  width: 100vw;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  background-image: url('../assets/map/backgrond.png');
  background-size: 100% 100%;
  box-sizing: border-box;
}
.content-title{
  width: 100%;
  height: 131px;
  background-image: url('../assets/map/heard.png');
  background-size: 100%;
  margin: 0 auto;
  position: relative;
}
.content-title-time{
  position: absolute;
  top: 50px;
  right: 266px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #DDDEE5;
  line-height: 1;
}
.toparea {
  position: absolute;
  top: 140px;
  left: 33%;
  z-index: 9;
}

.toparea .mapitem {
  display: inline;
  color: #ffffff;
}

.toparea .mapitem i {
  margin: 0 8px;
}

.toparea .mapitem span {
  cursor: pointer;
}
.content-cont{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 2.4%;
  box-sizing: border-box;
}
.cont-left{
  width: 26.8%;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cont-left-top{
  width: 100%;
  height: 64%;
  background-image: url('../assets/map/leftTop.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.cont-title-tier{
  display: flex;
  justify-content: center;
}
.cont-title{
  display: inline-block;
  height: 56px;
  padding: 0 42px;
  padding-top: 26px;
  box-sizing: border-box;
  background-image: url('../assets/map/contTitle.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1;
  margin-top: -35px;
}
.cont-left-bom{
  width: 100%;
  height: 28%;
  background-image: url('../assets/map/leftBom.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.cont-right{
  width: 26.8%;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.box {
  width: 39%;
  height: calc(100vh-180px);
  background-image: url('../assets/map/mapBackgrond.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* 弹窗样式 */
.echart-card {
  position: absolute;
  width: 230px;
  height: 240px;
  background-color: #f2f2f2;
  padding: 10px;
  color: #333;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
}

.set {
  margin-top: 8px;
  overflow-x: hidden;
  overflow-y: auto;
}

.flex {
  display: flex;
  align-items: center;
}

.qiyename {
  margin-left: 20px;
  width: 150px;
  text-align: left;
  overflow: hidden;
}
.agency{
  width: 100%;
  /* padding: 0 10%; */
  box-sizing: border-box;
}
.agency-list{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 28px;
}
.agency-item{
  width: 165px;
  height: 87px;
  background-image: url('../assets/map/agencyBack.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.agency-item-text{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.7;
  text-align: center;
}
.agency-title{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #DDDEE5;
  margin-top: 31px;
}
.agency-title img{
  width: 21px;
  height: 20px;
  margin-right: 9px;
}
.agency-columnar{
  width: 95%;
  height: 45%;
  margin: 0 auto;
  position: absolute;
  left: 2.5%;
  bottom: 1%;
}
.agency-tendency{
  width: 95%;
  height: 80%;
  margin: 0 auto;
  position: absolute;
  left: 2.5%;
  bottom: 1%;
  overflow: hidden;
}
.tier-list{
  width: 100%;
  height: 90%;
  padding: 0 5PX;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 1%;
  overflow: hidden;
}
.agency-proportion{
  width: 100%;
  height: 90%;
  padding: 0 5PX;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 1%;
}
</style>