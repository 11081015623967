<template>
  <div id="app">
    <Map></Map>
  </div>
</template>

<script>
import Map from "./components/map.vue";
export default {
  name: 'App',
  components: {
    Map
  }
}
</script>