<template>
  <div ref="content">
    <div class="mainSty" ref="main" :style="`height:${columnarHeight}px;`"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  data(){
    return{
      chart: undefined,
      data:[]
    }
  },
  props:{
    columnarHeight:{
      type: Number,
      default:0
    },
    dataList:{
        type:Array,
        default:[]
    }
  },
  watch:{
    columnarHeight(Val,oldVal){
      this.columnarHeight = Val
    //   console.log(this.columnarHeight)
    },
    dataList(val,oldVal){
        this.data = val
        setTimeout(()=>{
            let constat = this.$refs.main;
            this.chart = echarts.init(constat);
            this.init()
        },300)
    }
  },
  mounted(){
    
  },
  methods:{
    init(){
      var xData = [],
          yData = [];
      this.data.map(function(a, b) {
          xData.push(a.provinceName);
          yData.push(a.agentTotal);
      });
      var option = {
          color: ['#3398DB'],
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'line',
                  lineStyle: {
                      opacity: 0
                  }
              },
              formatter: function(prams) {
                 return "数量" +prams[0].data
              }
          },
          legend: {
              data: ['直接访问', '背景'],
              show: false
          },
          grid: {
              left: '5%',
              right: '5%',
              bottom: '5%',
              top: '7%',
              height: '85%',
              containLabel: true,
              z: 22
          },
          xAxis: [{
              type: 'category',
              gridIndex: 0,
              data: xData,
              axisTick: {
                  alignWithLabel: true
              },
              axisLine: {
                  lineStyle: {
                      color: '#0c3b71'
                  }
              },
              axisLabel: {
                  show: true,
                  color: 'rgb(170,170,170)',
                  fontSize:16
              }
          }],
          yAxis: [{
                  type: 'value',
                  nameTextStyle:{
                    color:"rgb(170,170,170)"  
                  },
                  gridIndex: 0,
                  splitLine: {
                      show: true,
                      lineStyle:{
                        color:'#131C65'
                      }
                  },
                  axisTick: {
                      show: false
                  },
                  axisLine: {
                      show:true,
                      lineStyle: {
                          color: '#0c3b71'
                      }
                  },
                  axisLabel: {
                      color: 'rgb(170,170,170)',
                      formatter: '{value}',
                  }
              },
              {
                  type: 'value',
                  gridIndex: 0,
                  max: 100,
                  splitNumber: 12,
                  splitLine: {
                      show: false
                  },
                  axisLine: {
                      show: false
                  },
                  axisTick: {
                      show: false
                  },
                  axisLabel: {
                      show: false
                  },
                  splitArea: {
                      show: false,
                      areaStyle: {
                          color: ['rgba(250,250,250,0.0)', 'rgba(250,250,250,0.05)']
                      }
                  }
              }
          ],
          series: [{
                  name: '数量',
                  type: 'bar',
                  barWidth: '30%',
                  xAxisIndex: 0,
                  yAxisIndex: 0,
                  itemStyle: {
                      normal: {
                          barBorderRadius: 30,
                          color: new echarts.graphic.LinearGradient(
                              0, 0, 0, 1, [{
                                      offset: 0,
                                      color: '#00D6E3'
                                  },
                                  {
                                      offset: 0.5,
                                      color: '#00BCDC'
                                  },
                                  {
                                      offset: 1,
                                      color: '#009FD3'
                                  }
                              ]
                          )
                      }
                  },
                  data: yData,
                  zlevel: 11

              },
              {
                  name: '背景',
                  type: 'bar',
                  barWidth: '50%',
                  xAxisIndex: 0,
                  yAxisIndex: 1,
                  barGap: '-135%',
                  data: [100, 100, 100, 100, 100, 100, 100,100, 100, 100, 100, 100, 100, 100,100],
                  itemStyle: {
                      normal: {
                          color: 'rgba(255,255,255,0.1)'
                      }
                  },
                  zlevel: 9
              },
            
          ]
      };
      this.chart.setOption(option);
      window.onresize = () => {
      this.chart.resize();
    };
    }
  }
}
</script>
<style>
.mainSty{
  width: 100%;
}
</style>